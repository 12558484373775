import { SVG } from '../../../assets/svg'

const ServiceSection = () => {
  const ServiceCard = ({ title, services, icon, index }) => {
    return (
      <div className="flex flex-col items-start text-white ">
        <div>
          <img src={icon} width={48} alt="logo" />
        </div>
        <h5 className="my-4 text-3xl text-white">{title}</h5>
        <div>
          <ul className="flex flex-col items-start gap-y-5">
            {services?.map((service, index) => (
              <li
                key={index}
                className="text-gray-400 transition-all cursor-pointer hover:text-white hover:font-semibold hover:translate-x-1"
              >
                {service?.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-delay={300}
        data-aos-offset="300"
        data-aos-duration={1000}
        className="px-5 py-10 mx-auto sm:px-10 max-w-7xl"
      >
        <div className="flex flex-col pb-20 mt-20 gap-y-5 md:flex-row">
          <h3 className="flex-1 text-3xl sm:text-4xl font-semibold text-left text-white leading-[1.4]">
            We're a full-service <br /> digital agency.
          </h3>
          <div className="flex-1 text-left">
            <h6 className="mb-3 font-medium uppercase text-primary">
              Services
            </h6>
            <p className="text-gray-400 ">
              We provide comprehensive IT services: website development,
              blockchain solutions, Web3 integration, product development, and
              mobile applications. Your one-stop digital solution.
            </p>
          </div>
        </div>
        <div className="grid gap-10 my-10 sm:grid-cols-2 sm:justify-center md:grid-cols-3">
          {serviceData?.map((items, index) => (
            <ServiceCard {...items} key={index} index={index} />
          ))}
        </div>
      </div>
    </>
  )
}
const serviceData = [
  {
    icon: SVG.DESIGN_SERVICE,
    title: 'Design',
    services: [
      {
        label: 'Mobile Apps Design',
      },
      {
        label: 'Website Design',
      },
      {
        label: 'UX/UI Design',
      },
    ],
  },
  {
    icon: SVG.DESIGN_SERVICE,
    title: 'Development',
    services: [
      {
        label: 'Mobile App Dev ',
      },
      {
        label: 'Web App Dev',
      },
      {
        label: 'Website',
      },
      {
        label: 'E-commerce Development',
      },
      {
        label: 'Backed Development',
      },
    ],
  },
  {
    icon: SVG.MODERN_SERVICE,
    title: 'Design',
    services: [
      {
        label: 'Mobile Apps Design',
      },
      {
        label: 'Website Design',
      },
      {
        label: 'UX/UI Design',
      },
    ],
  },
]

export default ServiceSection
