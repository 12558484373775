export const TokenFaqs = [
  {
    uid: 'what-is-blockchain',
    title: 'What is blockchain?',
    description: [
      'A blockchain is a ledger securely replicated across many machines. By distributing the same ledger across hundreds or thousands of machines, blockchains are more resilient to downtime events like power outages, hardware faults, natural disasters, and geopolitical censorship. To form consensus, these machines regularly collaborate to determine which sets of new transactions (“blocks”) are valid. These blocks of transactions link together to form a blockchain.',
      'This technology enables the development of permissionless decentralized applications. In other words, blockchains allow the creation of a new kind of digital application that do not require a central coordinator to operate. Think electronic money without central banks, digital asset trading without centrally-operated stock exchanges, or social network platforms without central administrators.',
    ],
  },
  {
    uid: 'what-is-sortcoder',
    title: 'What is Sortcoder?',
    description: [
      'Sortcoder is the internet of blockchains, an ecosystem of apps and services that exchange digital assets and data using IBC (Inter-Blockchain Communication) protocol.',
      'Sortcoder envisions that interoperable blockchain technology make the world economy more resilient through decentralization, more accountable through transparency, and more efficient through programmable value. The future economy consists of over a million interconnected blockchains used to exchange digitized real-world assets and provably scarce digital assets.',
    ],
  },
  {
    uid: 'what-is-projects-are-built-on-sortcoder',
    title: 'What projects are built on Sortcoder?',
    description: [
      'Developers have built hundreds of blockchain projects on Sortcoder, including Binance Chain (BNB), Polygon, ETH, Sortcoder, and more. Explore the Sortcoder ecosystem to discover a wide variety of apps, services, blockchains, wallets, and explorers that are part of the Sortcoder ecosystem.',
    ],
  },
  {
    uid: 'what-is-the-atom-token',
    title: 'What is SRT Token?',
    description: [
      'SRT is the primary token and powerhouse of the Sortcoder ecosystem. SRT is a BEP20 token that was originally launched on the Binance Smart Chain network. If you have SRT tokens, you can temporarily lock them to contribute to the security of Sortcoder using a mechanism called staking.',
      'In exchange for locking your SRT tokens, you receive rewards in the newly created SRT tokens. However, wagering is not risk free.',
    ],
  },
  {
    uid: 'what-is-a-crypto-wallet',
    title: 'What is a crypto wallet?',
    description: [
      'A crypto wallet is like a credit or debit card. The card offers several features so that you can access and spend your funds through the card. This is how the crypto wallet works. Crypto wallets keep your private keys – the passwords that give you access to your cryptocurrencies – secure and accessible, allowing you to send and receive cryptocurrencies.',
      'Crypto wallets in the blockchain universe do not store money equally, but give people access to their tokens and the ability to transact with them.',
    ],
  },
]
