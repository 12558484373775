import FEEDBACK_DATA from '../../../assets/images/feedback'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { Autoplay } from 'swiper'
import WORKS from '../../../assets/images/work'
import 'swiper/css'
import 'swiper/css/pagination'
const PortfolioSection = () => {
  const [swiper, setSwiper] = useState(null)

  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-delay="200"
      data-aos-offset="200"
      data-aos-duration="1000"
      className="relative overflow-hidden "
    >
      <svg
        width="2299"
        height="1150"
        viewBox="0 0 2299 1150"
        fill="none"
        initial="hidden"
        animate="visible"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-[10%] min-w-[500px] min-h-[500px] top-0 bottom-0 z-0 right-0 overflow-x-hidden"
      >
        <path
          d="M1333.62 1125L2264.19 204.599C2517.24 39.9728 -2241.71 2000.51 1407.09 25"
          stroke="url(#paint0_linear_329_12)"
          stroke-width="48.9796"
          stroke-linecap="round"
        />
        <defs>
          <linearGradient
            // linearGradient
            id="paint0_linear_329_12"
            x1="1193.5"
            y1="-211.176"
            x2="1960.82"
            y2="970.716"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF81FF" />
            <stop offset="1" stop-color="#F3BA2F" />
          </linearGradient>
        </defs>
      </svg>
      <div className="relative z-10 px-5 py-20 mx-auto sm:px-10 max-w-7xl">
        <div>
          <h6 className="mb-3 font-medium uppercase text-primary">OUR WORK</h6>
          <h3 className="text-3xl max-w-xl mx-auto sm:text-4xl font-semibold text-center text-white leading-[1.4]">
            Exploring Our Portfolio Highlights.
          </h3>
        </div>
        <div className="pt-20">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            loop
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            onSwiper={(s) => {
              setSwiper(s)
            }}
          >
            {WORKS.map((item, index) => (
              <SwiperSlide key={index}>
                <PortfolioCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex justify-between">
          <button className="hidden p-4 text-base font-semibold text-black capitalize bg-white rounded-md px-9">
            <span>Work with me</span>
          </button>
          <div className="flex gap-x-3 ">
            <button
              onClick={() => {
                swiper.slidePrev()
              }}
              className="p-5 text-base font-semibold text-white transition ease-in-out delay-100 bg-white rounded-md hover:text-black bg-opacity-10 hover:bg-white"
            >
              <span>
                <FiArrowLeft size={24} />
              </span>
            </button>
            <button
              onClick={() => {
                swiper.slideNext()
              }}
              className="px-5 text-base font-semibold text-white transition ease-in-out delay-100 bg-white rounded-md bg-opacity-10 hover:text-black hover:bg-white"
            >
              <span>
                <FiArrowRight size={24} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export const TestimonialSection = () => {
  // const [swiper, setSwiper] = useState(null)

  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-delay="200"
      data-aos-offset="200"
      data-aos-duration="1000"
      className="relative z-10 pt-20 bg-white "
    >
      <div className="px-5 mx-auto bg-white sm:px-10 max-w-7xl">
        <div>
          <h6 className="mb-3 font-medium uppercase text-primary">
            TESTIMONIAL
          </h6>
          <h3 className="text-3xl sm:text-5xl mb-3 capitalize font-semibold text-center leading-[1.4]">
            What Our Clients Say
          </h3>
          <p>
            Don't Take Our Word! See what a recent clients has to say about our
            work.
          </p>
        </div>
      </div>

      <div className="px-5 pt-10 sm:py-20">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          loop
          // autoplay={{ delay: 1000 }}
          modules={[Autoplay]}
          className="home-pagination"
          onSwiper={(s) => {
            // setSwiper(s)
          }}
        >
          {FEEDBACK_DATA.map((feedback, index) => (
            <SwiperSlide key={index}>
              <TestimonialCard
                clientName={feedback.clientName}
                content={feedback.content}
                star={feedback.star}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default PortfolioSection

const PortfolioCard = ({ image, title, date }) => {
  return (
    <div className="max-w-[400px] flex flex-col">
      <img src={image} alt={title} />
      <div className="flex items-end justify-between py-5">
        <div className="text-left">
          <p className="text-xs text-gray-400">Website: {date}</p>
          <h6 className="mb-3 text-lg font-medium text-white ">{title}</h6>
        </div>
        <div>
          <h6 className="mb-3 text-lg font-medium uppercase transition ease-in-out delay-150 cursor-pointer hover:font-semibold text-primary">
            VIEW PROJECT
          </h6>
        </div>
      </div>
    </div>
  )
}
const TestimonialCard = ({ content, clientName, star }) => {
  return (
    <>
      <div className="">
        <div className="mt-5 ">
          <p className="max-w-xl mx-auto text-lg font-medium sm:text-2xl">
            "{content}"
          </p>
        </div>
        <p className="flex justify-center max-w-xl mx-auto my-12 text-sm text-gray-400 gap-x-1">
          {Array(star)
            .fill(star)
            .map(() => (
              <img
                alt=""
                className="w-4 rounded-sm"
                src="https://asset.brandfetch.io/idjtYmGVfB/idjFWGgG2Q.png"
              />
            ))}
        </p>

        <div className="flex justify-center gap-x-6">
          <div>
            <img
              src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
              alt="client"
              width={40}
              className="object-cover rounded-full"
            />
          </div>

          <div className="text-left ">
            <h5 className="text-xl font-medium sm:text-3xl ">{clientName}</h5>
            {/* <p className="text-sm text-gray-400">CEO at Plastk Finance</p> */}
          </div>
        </div>
      </div>
    </>
  )
}
