import { useInView, useMotionValue, useSpring } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { SVG } from '../../../assets/svg'
import IMAGES from '../../../assets/images'
const AboutSection = () => {
  return (
    <>
      <div className="py-20 ">
        <div className="px-5 mx-auto sm:px-10 max-w-7xl">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay={300}
            data-aos-offset="300"
            data-aos-duration={1000}
          >
            <h6 className="mb-3 font-medium uppercase text-primary">
              ABOUT US
            </h6>
            <h3 className="text-3xl max-w-4xl mx-auto sm:text-5xl mb-3 font-semibold text-white text-center leading-[1.4]">
              We are Friendly and experienced digital agency.
            </h3>
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay={400}
            data-aos-offset="400"
            data-aos-duration={1000}
            className="flex flex-col pt-20 sm:flex-row"
          >
            <div className="flex-1 text-left text-gray-400">
              <p>
                Sortcoder, prominent digital agencies from India, have joined
                forces to offer a diverse range of cutting-edge services.
              </p>
              <br />
              <p>
                With a strong focus on innovation and a dedication to empowering
                startups and small organizations, our collaboration is
                redefining industry standards.
              </p>
              <br />
              <br />
              <button className="p-4 text-base font-semibold text-black bg-white rounded-md px-9">
                <span>Know More</span>
              </button>
            </div>
            <div className="flex-1 overflow-hidden">
              <img
                src={IMAGES.CONTACT_GRP2}
                alt=""
                className="overflow-hidden "
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export const ChooseSection = () => {
  return (
    <>
      <div className="relative py-20 mt-10 ">
        <img
          src={SVG.BLUE_SVG}
          alt=""
          className="absolute  md:w-[50%] bottom-0 right-0 md:left-[5%] blur-3xl "
        />
        <img
          alt=""
          src={SVG.PURPLE_SVG}
          className="absolute hidden md:block  md:w-[50%] bottom-0 right-0 blur-3xl "
        />

        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-delay={300}
          data-aos-offset="300"
          data-aos-duration={1000}
          className="relative z-10 px-5 mx-auto sm:px-10 max-w-7xl"
        >
          <div>
            <h6 className="mb-3 font-medium uppercase text-primary">
              WHY CHOOSE US?
            </h6>
            <h3 className="text-3xl sm:text-5xl mb-3 font-semibold text-white text-center leading-[1.4]">
              Make your brand
              <br /> awesome.
            </h3>
          </div>
          <div className="grid mt-20 sm:grid-cols-2 gap-y-20 md:grid-cols-4">
            {[
              {
                value: (
                  <>
                    <Counter value={90} />%
                  </>
                ),
                title: (
                  <span>
                    Overall Project complete <br />
                    Percentage 🚀
                  </span>
                ),
              },
              {
                value: (
                  <>
                    <Counter value={42} />+
                  </>
                ),
                title: <span>Complete Projects ✅</span>,
              },
              {
                value: (
                  <>
                    <Counter value={15} />+
                  </>
                ),
                title: <span>Services 🚚</span>,
              },
              {
                value: (
                  <>
                    <Counter value={50} />+
                  </>
                ),
                title: <span>Happy Clients 😃</span>,
              },
            ].map((item, index) => (
              <div key={index}>
                <h1 className="mb-3 text-6xl text-white">{item?.value}</h1>
                <p className="text-sm text-white">{item?.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

function Counter({ value, direction = 'up' }) {
  const ref = useRef(null)
  const motionValue = useMotionValue(direction === 'down' ? value : 0)
  const springValue = useSpring(motionValue, {
    damping: 100,
    stiffness: 100,
  })
  const isInView = useInView(ref, { once: true, margin: '-100px' })

  useEffect(() => {
    if (isInView) {
      motionValue.set(direction === 'down' ? 0 : value)
    }
  }, [motionValue, isInView])

  useEffect(
    () =>
      springValue.on('change', (latest) => {
        if (ref.current) {
          ref.current.textContent = Intl.NumberFormat('en-US').format(
            latest.toFixed(0),
          )
        }
      }),
    [springValue],
  )

  return <span ref={ref} />
}

export default AboutSection
