import { gql } from "@apollo/client";
const FeaturedBlog = gql`
query NewQuery($categoryName: String) {
  posts(where: {categoryName: $categoryName}) {
    nodes {
      id
      slug
      date
      uri
      title
      excerpt
      featuredImage {
        node {
          link
        }
      }
      tags {
        nodes {
          name
          uri
        }
      }
    }
  }
}
`;

const GetPost = gql`
  query SinglePost($id: ID = "") {
    post(idType: SLUG, id: $id) {
      id
      author {
        node {
          firstName
          avatar {
            url
          }
        }
      }
      categories(first: 10) {
        nodes {
          name
        }
      }
      tags(first: 10) {
        nodes {
          id
          name
        }
      }
      content
      date
      title
      featuredImage {
        node {
          link
        }
      }
      seo {
        canonical
        title
        metaDesc
        focuskw
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        breadcrumbs {
          url
          text
        }
        cornerstone
        schema {
          pageType
          articleType
          raw
        }
        readingTime
        fullHead
      }
    }
  }
`;

export { FeaturedBlog, GetPost };
