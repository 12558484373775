import { motion, useMotionValue } from 'framer-motion'
import { SVG } from '../../../assets/svg'
import { LuArrowRight } from 'react-icons/lu'
export const HeroSection = () => {
  const x = useMotionValue(0)
  const y = useMotionValue(0)

  const handleMouseMove = (e) => {
    const section = e.currentTarget.getBoundingClientRect()
    const mouseX = e.clientX - section.width / 2
    const mouseY = e.clientY - section.height / 2

    x.set(mouseX / 20)
    y.set(mouseY / 20)
  }

  const handleMouseLeave = () => {
    x.set(0)
    y.set(0)
  }

  return (
    <>
      <motion.div
        className="relative z-10 px-5 sm:px-10 "
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div className="relative h-full min-h-[80vh] mt-20 ">
          <div className="top-0 bottom-0 w-full h-full">
            <motion.img
              initial={false} // Don't animate on initial render
              style={{
                x: x,
                y: y,
              }}
              src={SVG.CIRCLE_SVG}
              width={20}
              alt="circle"
              className="absolute top-0 left-[10%]"
            />

            <motion.img
              initial={false} // Don't animate on initial render
              style={{
                x: x,
                y: y,
              }}
              src={SVG.CIRCLE_SVG}
              width={40}
              alt="circle"
              className="absolute bottom-64 left-[16%]"
            />
            <motion.img
              initial={false} // Don't animate on initial render
              style={{
                x: x,
                y: y,
              }}
              src={SVG.CIRCLE_SVG}
              width={20}
              alt="circle"
              className="absolute top-[10%] right-[36%]"
            />
            <motion.img
              initial={false} // Don't animate on initial render
              style={{
                x: x,
                y: y,
              }}
              src={SVG.CIRCLE_SVG}
              width={20}
              alt="circle"
              className="absolute top-[10%] right-[6%]"
            />
            <motion.img
              initial={false} // Don't animate on initial render
              style={{
                x: x,
                y: y,
              }}
              src={SVG.CIRCLE_SVG}
              width={20}
              alt="circle"
              className="absolute bottom-[50%] right-[16%]"
            />
          </div>

          <div className="relative z-10 flex flex-col gap-y-5 ">
            <div
              className="items-center"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="100"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-anchor-placement="top-bottom"
            >
              <img src={SVG.EMOJI} alt="emoji" className="w-30 h-30" />
            </div>

            <h6
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-offset="200"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-anchor-placement="top-bottom"
              className="font-medium text-primary"
            >
              WE’RE SORTCODER
            </h6>
            <h2
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="200"
              data-aos-offset="200"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <h1
                className="text-3xl sm:text-5xl max-w-3xl mx-auto font-semibold text-white leading-[1.4]"
                style={{
                  // marginBottom: '2.4rem',
                  marginTop: 0,
                  textAlign: 'center',
                  WebkitTextFillColor: 'transparent',
                  WebkitBackgroundClip: 'text',
                  backgroundImage:
                    'linear-gradient(142deg,#000 37%,#4827a2 72%,#4827a1 93%,#4827a1)',
                }}
              >
                Building Products, Brands & Experiences for You
              </h1>
            </h2>
            <p
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="200"
              data-aos-offset="250"
              data-aos-duration="1000"
              data-aos-once="true"
              className="text-black"
            >
              Explore our diverse range of successful projects and discover
              <br /> how we've helped our clients achieve their goals.
            </p>

            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="200"
              data-aos-offset="250"
              data-aos-duration="1000"
              data-aos-once="true"
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="56278e9abfbbba0bdcd568bc"
              data-businessunit-id="656b72b267b23c69bc895629"
              data-style-height="52px"
              data-style-width="100%"
            >
              <a
                href="https://www.trustpilot.com/review/sortcoder.tech"
                target="_blank"
                rel="noreferrer"
              >
                Trustpilot
              </a>
            </div>

            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="200"
              data-aos-offset="100"
              data-aos-duration="1000"
              data-aos-once="true"
              className="my-10 group"
            >
              <a
                className="font-semibold underline text-primary"
                href="https://www.upwork.com/freelancers/~01567a14a1df3e84cd?mp_source=share"
                target="_blank"
                rel="noreferrer"
              >
                <button className="relative p-4 text-base font-semibold text-white rounded-full px-9 bg-primary">
                  <div class="absolute transition-all ease-in-out duration-1000 opacity-70 -inset-px bg-gradient-to-r from-primary to-[#962DFF] rounded-xl blur-3xl group-hover:opacity-100 group-hover:-inset-1 flex  group-hover:duration-500 animate-tilt"></div>
                  <div className="flex items-center gap-1">
                    <div className="relative capitalize">Work with me</div>
                    <LuArrowRight size={18} />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  )
}
export const TrustedBrandsNameSection = () => {
  return (
    <>
      <div
        className="px-5 mx-auto max-w-7xl sm:px-10 "
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-delay="200"
        data-aos-offset="200"
        data-aos-duration="1000"
      >
        <div className="">
          <h4 className="my-10 text-xl font-medium text-white">
            Trusted by brands and startups
          </h4>

          <div className="flex justify-between overflow-x-hidden gap-x-3 opacity-60 ">
            <img src={SVG.BRAND1} alt="logog" />
            <img src={SVG.BRAND2} alt="logog" />
            <img src={SVG.BRAND3} alt="logog" />
            <img src={SVG.BRAND4} alt="logog" />
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
