import React, { useState } from 'react'
import Footer from '../Components/Footer'
import { Outlet } from 'react-router-dom'
import Header from '../Components/Header/Header'
import IMAGES from '../assets/images'
function MainLayout() {
  const [showSidebar, setShowSidebar] = useState(false)
  return (
    <div
      className={`h-screen bg-no-repeat ${showSidebar && 'overflow-y-hidden'}`}
    >
      <div
        className="absolute top-0 bottom-0 w-full bg-no-repeat -z-10"
        style={{
          paddingTop: '77rem',
          backgroundPosition: '50% 100%',
          backgroundSize: '100% 1300px',
          backgroundImage: `url(${IMAGES.BG_TOP})`,
          // backgroundImage: `url(https://assets-global.website-files.com/6527bc4d19181ea4f4e60b47/6527bf1dcd5ef92c30324349_Top%20Background.png)`,
        }}
      />
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Outlet />
      <Footer />
    </div>
  )
}
export default MainLayout
