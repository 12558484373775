import LOGO from './logo.svg'
import EMOJI from './emoji.svg'
import BRAND1 from './brands/brand1.svg'
import BRAND2 from './brands/brand2.svg'
import BRAND3 from './brands/brand3.svg'
import BRAND4 from './brands/brand4.svg'
import CIRCLE_SVG from './circle-home.svg'
import BLUE_SVG from './gradient/blue.svg'
import PURPLE_SVG from './gradient/purple.svg'
import DEV_SERVICE from './services/development.svg'
import MODERN_SERVICE from './services/modern.svg'
import DESIGN_SERVICE from './services/design.svg'
import LINE from './gradient/line.svg'

export const SVG = {
  LOGO: LOGO,
  EMOJI: EMOJI,
  BRAND1: BRAND1,
  BRAND2: BRAND2,
  BRAND3: BRAND3,
  BRAND4: BRAND4,
  LINE: LINE,
  CIRCLE_SVG: CIRCLE_SVG,
  BLUE_SVG: BLUE_SVG,
  PURPLE_SVG: PURPLE_SVG,
  DEV_SERVICE: DEV_SERVICE,
  DESIGN_SERVICE: DESIGN_SERVICE,
  MODERN_SERVICE: MODERN_SERVICE,
}
