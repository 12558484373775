import { MdClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BackgroundGradientAnimation } from './BackgroundGradinetAnimation'

function Sidebar({ showSidebar, onClose, routes }) {
  return (
    <div>
      <div
        className={`top-0 right-0 w-[100vw]   ease-in-out duration-300     text-white fixed bottom-0  h-screen z-[10000]  
          ${showSidebar ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <div className="z-[1000] w-full h-full absolute">
          <div
            onClick={() => onClose()}
            className="absolute cursor-pointer top-5 right-5"
          >
            <MdClose size={28} className="text-white" />
          </div>
          <div className="flex flex-col justify-around h-full mt-5 text-center">
            {routes?.map((item, index) => (
              <Link to={item?.link} key={index} onClick={() => onClose()}>
                <h2
                  key={index}
                  className="text-2xl font-semibold text-white uppercase duration-300 ease-in-out hover:translate-x-10"
                >
                  {item?.title}
                </h2>
              </Link>
            ))}
          </div>
        </div>
        <BackgroundGradientAnimation interactive={false} />
      </div>
    </div>
  )
}

export default Sidebar
