import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FeaturedBlog } from '../../graphql/query/BlogQuery'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import AdsComponent, { ADS_LAYOUT } from '../../Components/AdsComponets'
import PostCard from '../../Components/Cards/PostCard'
import axios from 'axios'
import { getImageSource } from '../../Utils/utility'
import sanityClient from '../../Config/client'

function BlogPage() {
  const [blogsData, setBlogsData] = useState([])

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        subtitle,
         "categories": categories[]->title,  
        _createdAt,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`,
      )
      .then((data) => setBlogsData(data))
      .catch(console.error)
  }, [])

  console.log(blogsData)
  const { data: featuredBlog } = useQuery(FeaturedBlog, {
    variables: { categoryName: 'featured' },
  })

  const { data: topBlog } = useQuery(FeaturedBlog, {
    variables: { categoryName: 'Top' },
  })

  const topPostImg = getImageSource(blogsData[0]?.description)

  return (
    <div className="relative">
      <div className="container ">
        <div className="absolute right-0 -z-[1]">
          <svg
            width="622"
            height="959"
            viewBox="0 0 622 959"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_5_213)">
              <path
                d="M519 745C687.447 745 824 608.447 824 440C824 271.553 687.447 135 519 135C350.553 135 214 271.553 214 440C214 608.447 350.553 745 519 745Z"
                fill="#962DFF"
                fill-opacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_5_213"
                x="0.279999"
                y="-78.72"
                width="1037.44"
                height="1037.44"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="106.86"
                  result="effect1_foregroundBlur_5_213"
                />
              </filter>
            </defs>
          </svg>
        </div>
        {/* //heading */}
        <div className="px-5 ">
          <div
            className="pb-20 pt-14"
            style={{
              flex: '1',
              textAlign: 'center',
              // padding: '26px 12px',
              fontFamily: 'Inter,sans-serif',
            }}
          >
            <h1
              className="mb-10 text-7xl  leading-[normal] "
              style={{
                // marginBottom: '2.4rem',
                marginTop: 0,
                textAlign: 'center',
                WebkitTextFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                backgroundImage:
                  'linear-gradient(142deg,#000 37%,#4827a2 72%,#4827a1 93%,#4827a1)',
              }}
            >
              The Blogs
            </h1>
            <p className="max-w-5xl px-16 mx-auto font-normal text-black text-md">
              We are building the future with Dapp and blockchain technologies
              to accelerate the world. Blockchain is our mission to build and
              make the most trusted and fastest growing dapps for startups and
              small organizations.
            </p>
          </div>

          <div>
            <div className="gap-10 md:flex">
              <div className="md:max-w-[50%] w-full">
                {blogsData && (
                  <TopPost
                    tags={blogsData[0]?.categories}
                    date={blogsData[0]?.pubDate}
                    title={blogsData[0]?.title}
                    excerpt={blogsData[0]?.description}
                    featuredImage={topPostImg?.[1]}
                    uri={blogsData[0]?.link}
                  />
                )}
                {/* {topBlog?.posts?.nodes[1] && (
                  <TopPost {...topBlog?.posts?.nodes[1]} />
                )} */}
                <AdsComponent layout={ADS_LAYOUT.fluid_Layout} />
              </div>
              <div className="md:max-w-[50%] w-full">
                <div className="text-left">
                  <h4 class="text-2xl text-white ">Feature Blog</h4>
                </div>
                {featuredBlog?.posts?.nodes?.map((data, key) =>
                  key === 2 ? (
                    <AdsComponent key={key} layout={ADS_LAYOUT.fluid_Layout} />
                  ) : (
                    <BlogSideCard {...data} key={key} />
                  ),
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="max-w-md my-20">
              <h2
                className="leading-[normal] m-10 text-6xl text-left"
                style={{
                  marginTop: 0,
                  WebkitTextFillColor: 'transparent',
                  WebkitBackgroundClip: 'text',
                  backgroundImage:
                    'linear-gradient(142deg,#ffffff 37%,#4827a2 72%,#4827a1 93%,#4827a1)',
                }}
              >
                Latest Blog
              </h2>
            </div>
            <div className="pb-10">
              <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {blogsData?.map((post, key) => {
                  return (
                    <PostCard
                      tags={post?.categories}
                      date={post?._createdAt}
                      title={post?.title}
                      excerpt={post?.subtitle}
                      featuredImage={post?.mainImage?.asset?.url}
                      uri={post?.slug?.current}
                      key={key}
                    />
                  )
                })}
              </div>
            </div>
            <AdsComponent layout={ADS_LAYOUT.fluid_Layout} />
          </div>
        </div>
      </div>
    </div>
  )
}

const BlogSideCard = ({ tags, date, title, excerpt, featuredImage, uri }) => {
  return (
    <>
      <div className="mt-6">
        <Link to={uri}>
          <div className="">
            <div className="flex gap-3">
              <div className="max-w-[60%] w-full">
                <div className="flex flex-wrap gap-2 mb-3 capitalize">
                  {_.take(tags?.nodes, 2).map((tag, index) => (
                    <span
                      key={index}
                      className="text-sm font-bold text_bg_color2"
                    >
                      #{tag.name}
                    </span>
                  ))}
                </div>
                <div className="text-left">
                  <h6 className="mb-2 text-white ">{title}</h6>
                  <div
                    className="mb-2 text-sm three-line feature-blog"
                    dangerouslySetInnerHTML={{ __html: excerpt }}
                  ></div>
                </div>
              </div>

              <div className="max-w-[40%] w-full flex  ">
                <img
                  src={featuredImage}
                  className="object-cover rounded-md"
                  alt=""
                />
              </div>
            </div>
            <div>
              <p className="text-sm font-semibold text-left text-white ">
                {/* January 13, 2023 */}
                {moment(date).format('LL')}
              </p>
            </div>
          </div>
        </Link>
        <div className="py-3 mx-5 border-b border-[#ffffff1c]"></div>
      </div>
    </>
  )
}

const TopPost = ({ title, excerpt, featuredImage, uri }) => {
  return (
    <>
      <div>
        <div>
          <img src={featuredImage} className="rounded-md" alt="" />
        </div>
        <div>
          <div className="flex mt-5">
            <span className="text-sm font-semibold text_bg_color2">
              Sortcoder
            </span>
          </div>
          <div className="text-left">
            <h3 className="my-2 text-3xl text-white ">{title}</h3>
            <div
              className="text-left text-white two-line"
              style={{
                fontSize: '10px !important',
              }}
              dangerouslySetInnerHTML={{ __html: excerpt }}
            ></div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BlogPage
