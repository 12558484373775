const FEEDBACK_DATA = [
  {
    content:
      'The team was able to implement in a really short time functionality into my AI application.',
    clientName: 'Doiu Cristian',
    star: 5,
    company: '',
  },
  {
    content:
      'The team was very easy to work with and extremely knowledgeable. We look forward to collaborating again on any future Blockchain and web3 projects. I also recommend the team to everyone for Blockchain and web3 projects.',
    clientName: 'Alex Turner',
    star: 4,
    company: '',
  },
  {
    content:
      'I had a recent bug in my Web3 app, and the development team quickly fixed it, demonstrating expertise in Web3 technology. Their clear communication and preventive measures impressed me. Highly satisfied with their technical skills and dedication; I highly recommend their bug-fixing services in the Web3 domain.',
    clientName: 'Lukas',
    star: 5,
    company: '',
  },
  {
    content:
      'It is always a pleasure to work with the team. We are ready to collaborate with him again in the future. He has good knowledge of full-stack development and other things.',
    clientName: 'David Thompson',
    star: 5,
    company: '',
  },
  {
    content:
      'Its been 4 months since I have been working with team. He is proactive in his approach. What I like best about him is his commitment to the project. He is very cooperative and ready to work whenever required.',
    clientName: 'Keshav Kumar',
    star: 5,
    company: '',
  },
  {
    content: 'Excellent work! Very fast and exceded my expectations',
    clientName: 'Agustin Ferrante',
    star: 5,
    company: '',
  },
  {
    content:
      'Ritik and Team is a really professional individual , we were impressed with the way how he he helped us solve an issue we were facing on the wallet integration! Highly recommended!',
    clientName: 'Sudeep Kulkarni',
    star: 5,
    company: '',
  },
]

export default FEEDBACK_DATA
