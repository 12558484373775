import { createContext, useState } from 'react'

export const LoadingContext = createContext()

const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  return (
    <LoadingContext.Provider
      value={{ setLoading: setLoading, loading: loading }}
    >
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
