import React from 'react'
import styled from 'styled-components'
import {
  FaTelegramPlane,
  FaDiscord,
  FaTwitter,
  FaYoutube,
  FaGithub,
  FaInstagram,
} from 'react-icons/fa'
import { Helmet } from 'react-helmet'
function TokenPage() {
  return (
    <>
      <Helmet>
        <title>Sortcoder: Token SRT </title>
      </Helmet>
      <div>
        <div>
          <div>
            <h1
              className="max-w-5xl px-5 mx-auto my-10 text-5xl font-semibold leading-snug lg:text-7xl"
              style={{
                WebkitTextFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                backgroundImage:
                  'linear-gradient(142deg,#000 37%,#4827a2 72%,#4827a1 93%,#4827a1)',
              }}
            >
              Explore new possibilities with our token
            </h1>
            <div>
              <div className="px-6 transition-all hover:bg-[#000000ab] py-3 mx-auto font-medium text-white bg-black text-lg  rounded-full cursor-pointer w-fit">
                <span>Get Token</span>
              </div>
            </div>
          </div>
          {/* How to use SRT Token */}
          <div>
            <GridWrapper>
              <Section2Container>
                <h2
                  className="mb-4 text-5xl"
                  style={{
                    WebkitTextFillColor: 'transparent',
                    WebkitBackgroundClip: 'text',
                    backgroundImage:
                      'linear-gradient(171deg,#fff 29%,#af92ff 69%,#6a42d8)',
                  }}
                >
                  Get SRT Token on many crypto exchanges.
                </h2>
              </Section2Container>
              <Section2Paragraph>
                Some exchanges offer staking services directly, but this
                requires you to delegate ownership of your tokens to them.
              </Section2Paragraph>
              <Section2Img data-aos="fade-up">
                <img
                  src={process.env.PUBLIC_URL + '/Assets/svg/stack-coin.png'}
                  alt="stack-coin"
                ></img>
              </Section2Img>
            </GridWrapper>
            <GridWrapper>
              <Section3Container>
                <h2
                  className="mb-4 text-5xl"
                  style={{
                    WebkitTextFillColor: 'transparent',
                    WebkitBackgroundClip: 'text',
                    backgroundImage:
                      'linear-gradient(171deg,#fff 29%,#af92ff 69%,#6a42d8)',
                  }}
                >
                  Transfer SRT Tokens to Wallet.
                </h2>
                <Section3Paragraph>
                  Some exchanges offer staking services directly, but this
                  requires you to delegate ownership of your tokens to them.
                </Section3Paragraph>
              </Section3Container>
              <Section3Img data-aos="fade-up">
                <img
                  src={process.env.PUBLIC_URL + '/Assets/svg/wallet-box.png'}
                  alt="stack-coin"
                ></img>
              </Section3Img>
            </GridWrapper>
            <GridWrapper>
              <Section4Container>
                <h2
                  className="mb-4 text-5xl"
                  style={{
                    WebkitTextFillColor: 'transparent',
                    WebkitBackgroundClip: 'text',
                    backgroundImage:
                      'linear-gradient(171deg,#fff 29%,#af92ff 69%,#6a42d8)',
                  }}
                >
                  Stake or Lock-Up SRT Token.
                </h2>

                <Section4Paragraph>
                  Send simple transactions through your wallet and start
                  Staking. You can earn more SRT tokens after maturity time is
                  over.
                </Section4Paragraph>
              </Section4Container>
              <Section4Img data-aos="fade-up">
                {' '}
                <img
                  src={process.env.PUBLIC_URL + '/Assets/svg/stake.png'}
                  alt="stack"
                ></img>
              </Section4Img>
            </GridWrapper>
          </div>
        </div>

        <div></div>
        {/* Communitiy Section  */}
        <div id="community" data-aos="fade-up">
          <GridWrapper>
            <CommunitiySectionContainer>
              <CommunitiySectionHeading2>
                <h2
                  className="mb-4 text-5xl"
                  style={{
                    WebkitTextFillColor: 'transparent',
                    WebkitBackgroundClip: 'text',
                    backgroundImage:
                      'linear-gradient(171deg,#fff 29%,#af92ff 69%,#6a42d8)',
                  }}
                >
                  Welcome to the community,
                </h2>
              </CommunitiySectionHeading2>
              <CommunitiySectionPara>
                Now that you have staked your SRT token like a pro, join the
                shortcodes community of creators, developers, and innovators
                from around the world who are creating a new era of the
                Internet.
              </CommunitiySectionPara>
              <CommunitiySectionSocialContainer>
                {CommunityData.map((data, key) => (
                  <CommunitiySocialWrapper href={data.url} target="_blank">
                    <CommunitiySocialIcon>{data.icon}</CommunitiySocialIcon>
                    <CommunitiySocialDes>
                      <h4>{data.title}</h4>
                      <p>{data.description}</p>
                    </CommunitiySocialDes>
                  </CommunitiySocialWrapper>
                ))}
              </CommunitiySectionSocialContainer>
            </CommunitiySectionContainer>
          </GridWrapper>
        </div>
      </div>
    </>
  )
}
const GridWrapper = styled.div`
  color: #fff;
  position: relative;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(8, 1fr);
    padding: 3rem 4rem;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(12, 1fr);
    padding: 6rem 8rem;
  }

  display: grid;
  padding: 3rem 1.3rem;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 1rem;
  gap: 0 1rem;
`
const Section2Container = styled.div`
  text-align: left;
  grid-column-start: 1;
  grid-column-end: -1;
  position: relative;
  z-index: 200;

  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 6;
    grid-column-end: 13;
  }
`
const Section2Paragraph = styled.p`
  grid-column-start: 1;
  grid-column-end: -1;
  text-align: left;
  font-size: 20px;
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 6;
    grid-column-end: 11;
    h2 {
      font-size: 3.3rem;
    }
  }
`
const Section2Img = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    /* position: absolute; */
    max-width: 500px;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
    align-items: flex-end;
    img {
      position: absolute;
      width: 100%;
    }
  }
  /* display: flex;
  justify-content: flex-end; */
`
const Section3Container = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  text-align: left;
  h2 {
    margin: 0;
    font-size: 2.5rem;
    line-height: 1.12;
  }
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 2;
    grid-column-end: 7;
    h2 {
      font-size: 3.3rem;
    }
  }
`
const Section3Paragraph = styled.p`
  grid-column-start: 1;
  grid-column-end: -1;
  text-align: left;
  font-size: 20px;
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 2;
    grid-column-end: 7;
    h2 {
      font-size: 3.3rem;
    }
  }
`
const Section3Img = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    /* position: absolute; */
    max-width: 500px;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 3;
    align-items: flex-end;
    img {
      position: absolute;
      width: 100%;
    }
  }
  /* display: flex;
  justify-content: flex-end; */
`
const Section4Container = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  text-align: left;
  h2 {
    margin: 0;
    font-size: 2.5rem;
    line-height: 1.12;
  }
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 6;
    grid-column-end: 12;
    h2 {
      font-size: 3.3rem;
    }
  }
`

const Section4Paragraph = styled.p`
  grid-column-start: 1;
  grid-column-end: -1;
  text-align: left;
  font-size: 20px;
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 6;
    grid-column-end: 11;
    h2 {
      font-size: 3.3rem;
    }
  }
`
const Section4Img = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    /* position: absolute; */
    max-width: 500px;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
    align-items: flex-end;
    img {
      position: absolute;
      width: 100%;
    }
  }
  /* display: flex;
  justify-content: flex-end; */
`
const CommunitiySectionHeading2 = styled.h2`
  text-align: left;
  margin: 0;
  font-size: 2.5rem;
  line-height: 1.12;
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    font-size: 3.3rem;
  }
`

const CommunitiySectionPara = styled.p`
  text-align: left;
  font-size: 20px;
  max-width: 34em;
`

const CommunitiySectionContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1200px) {
    grid-column-start: 2;
    grid-column-end: 11;
  }
`
const CommunitiySectionSocialContainer = styled.div`
  display: grid;

  grid-gap: 0 1rem;
  grid-row-gap: 4rem;
  row-gap: 4rem;
  margin-top: 4rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  }
`

const CommunitiySocialWrapper = styled.a`
  display: grid;
  grid-template-columns: min-content 1fr;
  color: #fff;
  cursor: pointer;
  grid-gap: 2rem;
  gap: 2rem;
  transform: translateZ(0);
  /* background: #1319234a; */
  padding: 20px;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  :hover {
    background: #1319234f;
    color: #ffffffd1;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.4s ease-out;
    transform: translateY(-2px);
    transition-duration: 0.2s;
  }
`

const CommunitiySocialIcon = styled.div`
  display: flex;
  margin-top: 8px;
`
const CommunitiySocialDes = styled.div`
  text-align: left;
  h4 {
    font-size: 1.8rem;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 1rem;
    color: #ffffff80;
    max-width: 24em;
  }
`
export default TokenPage

const CommunityData = [
  {
    title: 'Community Chat',
    description: 'Ask FAQ and chat with worldwide problem on Telegram.',
    icon: <FaTelegramPlane size={30} />,
    url: 'https://t.me/sortcoder',
  },
  {
    title: 'Developer Chat',
    description:
      'If you have any technical questions or doubts? Ask the developer team at Discord.',
    icon: <FaDiscord size={30} />,
    url: 'https://discord.gg/kx3yrukNcD',
  },
  {
    title: 'Twitter',
    description:
      'Follow @sortcoder to get the latest news and information from across the ecosystem.',
    icon: <FaTwitter size={30} />,
    url: 'https://twitter.com/sortcoder',
  },
  {
    title: 'Youtube',
    description:
      'If you want to know? How the technology works You can check our channel.',
    icon: <FaYoutube size={30} />,
    url: 'https://www.youtube.com/channel/UClxpXUYmmvmTqpsRrYYa3AA',
  },
  {
    title: 'GitHub',
    description:
      'If you want code of project,we have done you can check our repository at GitHub.',
    icon: <FaGithub size={30} />,
    url: 'https://github.com/sortcoder',
  },
  {
    title: 'Instagram',
    description: 'Follow @sortcoder to get more information about Sortcoder',
    icon: <FaInstagram size={30} />,
    url: 'https://www.instagram.com/sortcoder/',
  },
]
