import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import TeamList from '../Components/TeamCard/TeamList'
import { Helmet } from 'react-helmet'
import { LuArrowRight } from 'react-icons/lu'
function AboutPage() {
  return (
    <>
      <Helmet>
        <title>Sortcoder: About </title>
      </Helmet>
      <div className="max-w-5xl mx-auto mt-10">
        {/* About */}
        <>
          <div
            style={{
              flex: '1',
              textAlign: 'center',
              padding: '26px 12px',
              fontFamily: 'Inter,sans-serif',
            }}
          >
            <h1
              className="mb-10 text-7xl"
              style={{
                // marginBottom: '2.4rem',
                marginTop: 0,
                textAlign: 'center',
                WebkitTextFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                backgroundImage:
                  'linear-gradient(142deg,#000 37%,#4827a2 72%,#4827a1 93%,#4827a1)',
              }}
            >
              Welcome to Sortcoder
            </h1>
            <p className="px-16 font-normal text-black text-md">
              We are building the future with Dapp and blockchain technologies
              to accelerate the world. Blockchain is our mission to build and
              make the most trusted and fastest growing dapps for startups and
              small organizations.
            </p>
            <div
              style={{
                height: '500px',
                padding: '26px 12px',
                margin: '0 auto',
                maxWidth: '840px',
                marginTop: '64px',
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/lqN_DvM_e1M"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </>

        <SectionsWrapper>
          {SectionsData.map((item, key) => (
            <Section item={item} key={key} />
          ))}
          <div style={{ position: 'relative' }}>
            <div
              style={{
                backgroundColor: '#fff',
                width: 'fit-content',
                borderRadius: '5px',
                padding: '20px 45px',
                fontWeight: '540',
                fontSize: '1.02rem',

                boxShadow:
                  '0px 0px 1px rgba(0,0,0,0.04),0px 2px 4px rgba(0,0,0,0.04),0px 5px 11px rgba(0,3,66,0.07);',
              }}
            >
              <Link style={{ color: '#000' }} to="/token">
                Get Token
              </Link>
            </div>
          </div>
        </SectionsWrapper>
        {/* About */}
        <div>
          <TimeLineWrapper>
            <TimeLineBGImage
              Left="-20%"
              Right="auto"
              Top="10%"
              Time="10s"
              src={process.env.PUBLIC_URL + '/Assets/blurBlob.svg'}
              alt="blur"
            />
            <TimeLineBGImage
              Left="auto"
              Right="-20%"
              Top="-24%"
              Time="16s"
              src={process.env.PUBLIC_URL + '/Assets/blurBlob.svg'}
              alt="blur"
            />
            <h4>
              Sortcoder <span>Timeline</span>{' '}
            </h4>
            <SortcoderTimeLineWrapper>
              {TimeLineData.map((data, key) => (
                <SortcoderTimeLine
                  key={key}
                  top={data?.top}
                  squarecolor={data.squarecolor}
                  endcolor={data.endcolor}
                  data-aos="fade-right"
                  data-aos-duration="3000"
                >
                  <h3>{data.year}</h3>
                  <p>{data.text}</p>
                </SortcoderTimeLine>
              ))}
            </SortcoderTimeLineWrapper>
          </TimeLineWrapper>
        </div>

        {/* Team Section */}
        {/* <TeamsSection /> */}
        {/* //Contact Section */}
        <ContactSection>
          <div style={{ margin: '40px 0', fontSize: '40px', color: 'white' }}>
            Connect with us. Build with us.
          </div>
          <a
            className="font-semibold underline text-primary"
            href="https://www.upwork.com/freelancers/~01567a14a1df3e84cd?mp_source=share"
            target="_blank"
            rel="noreferrer"
          >
            <button className="relative p-4 text-base font-semibold text-white rounded-full px-9 bg-primary">
              <div class="absolute transition-all ease-in-out duration-1000 opacity-70 -inset-px bg-gradient-to-r from-primary to-[#962DFF] rounded-xl blur-3xl group-hover:opacity-100 group-hover:-inset-1 flex  group-hover:duration-500 animate-tilt"></div>

              <div className="flex items-center gap-1">
                <div className="relative capitalize">Work with me</div>
                <LuArrowRight size={18} />
              </div>
            </button>
          </a>
        </ContactSection>
      </div>
    </>
  )
}

const TeamsSection = () => {
  return (
    <>
      <TeamSection>
        <h4>
          Sortcoder/Threnloven <span>Team</span>
        </h4>
        <p>The team that makes it all possible.</p>
        <TeamList />
      </TeamSection>
    </>
  )
}

const SortcoderTimeLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: white;

  position: relative;
  h3 {
    margin: 0;
    font-size: 34px;
    font-weight: 500;
  }
  p {
    margin: 0;
    margin-top: 10px;
  }
`
const SectionsWrapper = styled.div`
  max-width: 1028px;
  margin: 0 auto;
  padding: 0 23px;
`
const TimeLineWrapper = styled.div`
  padding: 3rem 0;
  font-size: 4rem;
  max-width: 1028px;
  margin: 0 auto;
  text-align: left;
  h4 {
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.25em;
    word-spacing: 2px;
    color: #fff;
    position: relative;
    z-index: 2;
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  }
`
const SortcoderTimeLine = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  padding-left: 2rem;
  position: relative;
`

const TimeLineBGImage = styled.img`
  animation: bluranim ${(props) => props.Time} infinite;
  position: absolute;
  left: ${(props) => props.Left};
  top: ${(props) => props.Top};
  right: 0;
  bottom: auto;
  z-index: -2;
  max-width: 60%;
  transform: translate3d(0px, 157.529px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  will-change: transform;
  @media (min-width: 640px) {
    /* right: ${(props) => props.Right}; */
  }
`
const TeamSection = styled(TimeLineWrapper)`
  color: #fff;
`
const ContactSection = styled.div`
  max-width: 1028px;
  margin: 0 auto;
  text-align: left;
  font-weight: 500;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
  background-color: #1319234a;
  padding: 20px 12px;
  border-radius: 30px;
  @media (min-width: 992px) {
    padding: 70px 72px;
  }
`
const TimeLineData = [
  {
    year: 2019,
    text:
      'Sortcoder is founded with a vision to revolutionize the tech industry',
    squarecolor: '#a4f8fe',
    endcolor: '#9f54d5',
  },
  {
    year: 2020,
    text:
      'Expansion of services to include web development and cloud solutions.',
    squarecolor: '#9f54d5',
    endcolor: '#9f54d5',
  },
  {
    year: 2021,
    text:
      'Embracing emerging technologies such as blockchain and introduction of new services',
    squarecolor: '#c95c80',
    endcolor: '#9f54d5',
  },
  {
    year: `Current`,
    text:
      'Continued dedication to innovation and excellence in all aspects of operations',
    top: 4,
    squarecolor: '#e35e45',
    endcolor: '#9f54d5',
  },
]
export default AboutPage

const Section = (props) => {
  const SectionContainer = styled.div`
    text-align: left;
    color: #fff;
    display: grid;
    align-items: center;
    margin-top: 6rem;
    grid-gap: 0 1rem;
    gap: 0 1rem;
    grid-auto-flow: dense;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(8, 1fr);
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 0 2rem;
      gap: 0 2rem;
    }

    :nth-child(odd) {
      .sect2 {
        display: flex;
        justify-content: center;
      }
      @media screen and (min-width: 768px) {
        .sect2 {
          grid-column-start: 1;
          grid-column-end: 9;
          display: flex;
          justify-content: center;
        }
        .sect1 {
          grid-column-start: 1;
          grid-column-end: 9;
          display: block;
        }
      }
      @media screen and (min-width: 1200px) {
        .sect1 {
          grid-column-start: 1;
          grid-column-end: 7;
        }
        .sect2 {
          grid-column-start: 7;
          grid-column-end: 12;
          display: block;
        }
      }
    }
    :nth-child(even) {
      .sect2 {
        display: flex;
        justify-content: center;
      }
      @media screen and (min-width: 768px) {
        .sect2 {
          grid-column-start: 1;
          grid-column-end: 9;
          display: block;
        }
        .sect1 {
          grid-column-start: 1;
          grid-column-end: 9;
        }
      }
      @media screen and (min-width: 1200px) {
        .sect2 {
          grid-column-start: 1;
          grid-column-end: 6;
          display: block;
        }
        .sect1 {
          grid-column-start: 6;
          grid-column-end: 12;
        }
      }
    }
  `
  const Paragraph = styled.p`
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; */
    -webkit-box-orient: vertical;
    font-size: 1rem;
    margin-top: 1rem;
    color: #ffffffd1;
    margin-bottom: 4rem;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      margin-top: 1rem;
      color: #ffffffd1;
      margin-bottom: 0;
    }
  `

  const Heading4 = styled.h4`
    line-height: 1.777rem;
    word-spacing: 2px;
    color: #fff;
    font-size: 2.4rem;
    line-height: 1.174849;
    margin: 0;
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #faaef2, #00dfd8);
    }
  `

  return (
    <>
      <SectionContainer data={props.item} data-aos="zoom-out-up">
        <div className="sect1">
          <Heading4>{props.item.title}</Heading4>
          <Paragraph className="md:pb-10 text-md">{props.item.text}</Paragraph>
        </div>
        <div className="sect2">
          <img
            alt="ds"
            src={props.item.img}
            width="100%"
            style={{ maxWidth: '500px' }}
          ></img>
        </div>
      </SectionContainer>
    </>
  )
}

const SectionsData = [
  {
    title: 'Organization',
    text:
      'At Sortcoder, we innovate with blockchain,cloud, and IT service tech. We connect, empower, and elevate user experiences. Join us to shape the future of technology and enhance lives globally!"',
    flexDirection: 'reverse',
    img: process.env.PUBLIC_URL + '/Assets/svg/organization.png',
  },
  {
    title: 'Technologies',
    text:
      'We use the newest technology to build our projects. Our focus on staying updated ensures great outcomes for our clients',
    img: process.env.PUBLIC_URL + '/Assets/svg/technology.png',
  },
  {
    title: 'Sortcoder Token',
    text:
      'We will soon introduce our new token to the community. Stay tuned for the launch!"',
    img: process.env.PUBLIC_URL + '/Assets/svg/coin02.png',
  },
]
