import React from 'react'
import styled from 'styled-components'
function TeamCard(props) {
  return (
    <TeamCardWrapper data-aos="fade-down" data-aos-duration="4000">
      <div className="flex justify-center">
        <img
          src={props.data.img}
          loading="lazy"
          width="156"
          alt=""
          class="avatar"
        ></img>
      </div>
      <SubHeading>{props.data.title}</SubHeading>
      <Designation>{props.data.designation}</Designation>
    </TeamCardWrapper>
  )
}

const TeamCardWrapper = styled.div`
  color: white;
  background: #1319234a;
  cursor: pointer;
  padding: 40px;
  border-radius: 10px;
  justify-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  text-align: center;
  grid-template-rows: max-content max-content max-content;

  :hover {
    background-blend-mode: color-dodge;
    background-image: linear-gradient(180deg, #181e39, #060b27 0%),
      url('https://assets.website-files.com/6159b2a6c3fdecab90610326/6165143d375b80d077dff60f_Team%20Card%20Bg.png'),
      radial-gradient(circle closest-corner at 50% 50%, #181e39, #060b27);
    background-position: 0px 0px, 50% 50%, 0px 0px;
    background-size: auto, cover, auto;
    background-repeat: repeat, no-repeat, repeat;
  }
`
const SubHeading = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
`
const Designation = styled.div`
  font-size: 14px;
  line-height: 24px;
`
export default TeamCard
