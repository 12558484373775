import React from 'react'
import { NavLink } from 'react-router-dom'
import IMAGES from '../../assets/images'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'

function Header({ showSidebar, setShowSidebar }) {
  const routes = [
    { title: 'Features', link: '/' },
    { title: 'Token', link: '/token' },
    { title: 'About us', link: '/about' },
    { title: 'Blogs', link: '/blog' },
    { title: 'Contact', link: '/contact' },
  ]
  return (
    <div>
      <div className="h-[80px] flex items-center justify-between md:px-10 px-5">
        <div className="flex items-center gap-x-5">
          <NavLink to="/">
            <img src={IMAGES.LOGO_BLACK} alt="done" className="w-[150px]" />
          </NavLink>
          <nav className="hidden font-medium text-black md:flex">
            {routes.map((item, index) => (
              <div key={index} className="px-3 hover:text-[#383838]">
                <Link to={item.link}>{item.title}</Link>
              </div>
            ))}
          </nav>
        </div>
        <div className="md:hidden" onClick={() => setShowSidebar(true)}>
          <div class="flex h-screen    w-full items-center justify-center scale-75">
            <div class="group flex  cursor-pointer items-center justify-center rounded-3xl  ">
              <div class="space-y-2">
                <span class="block h-1 w-10 origin-center rounded-full bg-black transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45"></span>
                <span class="block h-1 w-8 origin-center rounded-full bg-primary transition-transform ease-in-out group-hover:w-10 group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        routes={routes}
        showSidebar={showSidebar}
        onClose={() => setShowSidebar(false)}
      />
    </div>
  )
}

export default Header
