import React, { useEffect } from "react";

const AdsComponent = (props) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <>
      <div>{props.layout}</div>
    </>
  );
};

export const ADS_LAYOUT = {
  auto_Layout: (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-4548722974359094"
      data-ad-slot={"2510415280"}
      data-ad-format="auto"
      // data-adtest="on"
      data-full-width-responsive="true"
    ></ins>
  ),
  fluid_Layout: (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-layout-key="-fw-7+5a-6u-7j"
      data-ad-client="ca-pub-4548722974359094"
      data-ad-slot="9151674437"
    ></ins>
  ),
  horizontal_Layout: (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-4548722974359094"
      data-ad-slot="4103415787"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  ),
  article_Layout: (
    <ins
      className="adsbygoogle"
      style={{ display: "block", textAlign: "center" }}
      data-ad-layout="in-article"
      data-ad-format="fluid"
      data-ad-client="ca-pub-4548722974359094"
      data-ad-slot="8621245290"
    ></ins>
  )
};
export default AdsComponent;
