import { FiPlus } from 'react-icons/fi'
import IMAGES from '../../../assets/images'

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'react-headless-accordion'
import { Link } from 'react-router-dom'

const FAQSection = () => {
  return (
    <div className="pt-24 pb-20 bg-white ">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-delay={500}
        data-aos-offset="300"
        data-aos-duration={1000}
        className="px-5 mx-auto bg-white sm:px-10 max-w-7xl"
      >
        <div>
          <h6 className="mb-3 font-medium uppercase text-primary">FAQ’S</h6>
          <h3 className="text-3xl sm:text-5xl mb-3  font-semibold text-center leading-[1.4]">
            Frequently Asked Questions
          </h3>
        </div>
        <div className="my-10 sm:my-20">
          <Accordion>
            {FAQS.map((item, index) => (
              <AccordionItem key={index}>
                <div className="py-5 mb-3 bg-gray-100 rounded-xl">
                  <AccordionHeader className="w-full ">
                    <div className="flex items-center justify-between px-5 sm:px-10 ">
                      <h5 className="font-semibold text-left text-black">
                        {item?.title}
                      </h5>
                      <FiPlus />
                    </div>
                  </AccordionHeader>

                  <AccordionBody>
                    <div className="px-10 mt-4 text-left">{item?.content}</div>
                  </AccordionBody>
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export const ReadyWorkSection = () => {
  return (
    <div
      className="py-10 bg-no-repeat"
      style={{
        backgroundPosition: '50% 50%,0 0',
        backgroundImage:
          'url(https://assets-global.website-files.com/6527bc4d19181ea4f4e60b47/6527bf1c43b13d9472451a29_Block%20Top%20Background.png)',
      }}
    >
      <div className="px-5 sm:px-10 ">
        <div className="flex py-16">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay={300}
            data-aos-offset="300"
            data-aos-duration={1000}
            className="flex-1 text-left text-white "
          >
            <h3 className=" text-3xl sm:text-5xl mb-3 font-semibold  leading-[1.4]">
              Ready to work
              <br /> with us?
            </h3>
            <p>
              We are a creative agency that creates products and provides
              services to our clients.
            </p>
            <div className="flex items-center mt-8 cursor-pointer gap-x-10 divide-opacity-25">
              <img
                width={52}
                className="transition-all hover:-translate-y-3 hover:scale-150"
                alt="upwork"
                src="https://companieslogo.com/img/orig/UPWK-5c9be18b.png?t=1660465386"
              />
              <img
                width={52}
                className="transition-all hover:-translate-y-3 hover:scale-150"
                alt="fivver"
                src="https://cdn.worldvectorlogo.com/logos/fiverr-1.svg"
              />
              <img
                width={52}
                className="transition-all hover:-translate-y-3 hover:scale-150"
                alt="freelancer"
                src="https://cdn.worldvectorlogo.com/logos/freelancer-1.svg"
              />

              <img
                width={52}
                className="transition-all hover:-translate-y-3 hover:scale-150"
                alt="freelancer"
                src="https://mailmeteor.com/logos/assets/PNG/Gmail_Logo_512px.png"
              />
            </div>
          </div>
        </div>
        <img
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-delay={350}
          data-aos-offset="350"
          data-aos-duration={1000}
          src={IMAGES.CONTACT_GRP}
          alt=""
          className="md:w-[800px]"
        />
      </div>
    </div>
  )
}

const FAQS = [
  {
    title: 'What services does your agency offer?',
    content: (
      <div>
        <div>We specialize in a wide range of IT services, including{'\n'}</div>
        <ul className="mt-4 list-disc ">
          <li>Web Development (Frontend & Backend)</li>
          <li>Mobile App Development (iOS & Android)</li>
          <li>
            Blockchain Development (dApps, Smart Contracts, Web3 and more)
          </li>
          <li>Cloud Solutions</li>
          <li>E-commerce Solutions</li>
          <li>API Development & Integration</li>
          <li>Custom Software Development</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Can you handle both small and large-scale projects?',
    content: `Yes, we have the capability to handle projects of all sizes. Whether you're a startup looking to build your first mobile app or a large enterprise in need of complex software solutions, we can cater to your requirements effectively.`,
  },
  {
    title: 'What technologies do you specialize in?',
    content: `We are at the forefront of the latest technologies and frameworks, constantly staying updated with the rapidly evolving landscape of IT`,
  },
  {
    title: 'Do you provide ongoing support and maintenance?',
    content: `Yes, we offer ongoing support and maintenance services to ensure that your applications and websites remain up-to-date, secure, and optimized for performance. Our team is available to address any issues or updates that may arise post-launch.`,
  },
  {
    title: 'Do you offer custom solutions?',
    content: `Yes, we provide custom solutions based on your business needs, whether it's web development, blockchain integration, mobile apps, or cloud services. Our goal is to build software that scales with your business.`,
  },
  {
    title: 'How do you handle data security?',
    content: `We prioritize security by implementing best practices in every project, such as encryption, secure APIs, and regular audits. Our DevOps and cloud solutions also follow strict security protocols.`,
  },
  {
    title: 'How do we get started with your agency?',
    content: (
      <div>
        <div>
          Getting started is easy! Simply contact us with your project
          requirements, and we’ll schedule a consultation to discuss your needs,
          timeline, and budget. After that, we’ll provide a detailed project
          plan and proposal.
        </div>
        <a
          className="font-semibold underline text-primary"
          href="https://www.upwork.com/freelancers/~01567a14a1df3e84cd?mp_source=share"
          target="_blank"
          rel="noreferrer"
        >
          Contact here
        </a>
      </div>
    ),
  },
]

export default FAQSection
