import IMAGES from '../../assets/images'
import moment from 'moment'
import _ from 'lodash'
import { Link } from 'react-router-dom'
const PostCard = ({ tags, date, title, excerpt, featuredImage, uri }) => {
  console.log(featuredImage)
  return (
    <div
      className="transition-all bg-black cursor-pointer hover:-translate-y-8 rounded-3xl"
      style={{
        backgroundImage: `url(${IMAGES.BG1})`,
        backgroundPosition: '50% 20%',
        backgroundSize: '964px',
      }}
    >
      <div className="h-full bg-no-repeat ">
        <div className="relative w-full overflow-hidden pt-[58%] rounded-t-3xl ">
          <Link to={`/blog/${uri}`}>
            <img
              src={featuredImage}
              className="absolute top-0 bottom-0 left-0 right-0 object-cover w-full h-full"
              alt=""
            />
          </Link>
        </div>
        {/* // content */}
        <div className="px-5 pt-6 pb-10">
          <div className="flex flex-wrap items-center gap-2 mb-5 capitalize">
            {_.take(tags, 3).map((tag, index) => (
              <span
                key={index}
                className="p-1 px-2 text-xs font-semibold rounded-full bg-primary "
              >
                {tag}
              </span>
            ))}
          </div>

          {console.log(uri)}
          <Link to={`/blog/${uri}`}>
            <h3
              className="mb-3 text-2xl font-bold text-left"
              style={{
                fontFamily: 'Inter,sans-serif',
                WebkitTextFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                backgroundImage:
                  'linear-gradient(135deg,#fff 38%,#af92ff 70%,#426dd8)',
              }}
            >
              {title}
            </h3>
            <div
              className="mb-2 text-left text-white three-line"
              style={{
                fontSize: '10px !important',
              }}
              dangerouslySetInnerHTML={{ __html: excerpt }}
            ></div>
            <div className="flex justify-between mt-8">
              <p className="text-sm font-normal text-right text-white ">
                {moment(date).format('LL')}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default PostCard
