import React from 'react'
import BlogPage from '../Pages/Blog/BlogPage'
import PostPage from '../Pages/Blog/PostPage'
// import HomePage from '../Pages/HomePage'
import { Routes, Route } from 'react-router-dom'
import AboutPage from '../Pages/AboutPage'
import TokenFAQ from '../Pages/TokenPages/TokenFAQ'
import TokenFAQs from '../Pages/TokenPages/TokenFAQs'
import TokenPage from '../Pages/TokenPages/TokenPage'
import PageNotFoundPage from '../Pages/404/PageNotFoundPage'
import Roadmap from '../Pages/Roadmap'
import PrivacyPage from '../Pages/Terms/PrivacyPage'

import HomePage from '../Pages/Home/HomePage'
import MainLayout from '../layout/MainLayout'
export default function Routing(props) {
  return (
    <>
      <Routes>
        {/* //Header links */}
        <Route element={<MainLayout />}>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/:year/:month/:date/:slug" element={<PostPage />} />
          <Route path="/token" element={<TokenPage />} />
          <Route path="/roadmap" element={<Roadmap />} />
        </Route>

        <Route element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<PostPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />

          {/* //Faq */}
          <Route path="/faq/" element={<TokenFAQs />} />
          <Route path="/faq/:uid" element={<TokenFAQ />} />
          <Route path="*" element={<PageNotFoundPage />} />
        </Route>
      </Routes>
    </>
  )
}
