import React, { useEffect, useContext } from 'react'
import './App.css'
import './index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Routing from './Routes'
import ScrollToTop from './Helpers/ScrollTop'
import { LoadingContext } from './Provider/LoadingProvider'
import { useLocation } from 'react-router-dom'
function App() {
  const { loading, setLoading } = useContext(LoadingContext)
  const { pathname } = useLocation()

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      //   // Wait for two second
      if (pathname === '/') {
        await new Promise((r) => setTimeout(r, 5000))
      } else {
        // await new Promise((r) => setTimeout(r, 2000))
      }
      // Toggle loading state
      setLoading(false)
    }
    loadData()
  }, [pathname])

  if (loading) {
    // return <Loading />
  }
  return (
    <div className="App">
      <ScrollToTop />
      <Routing />
    </div>
  )
}

export default App
